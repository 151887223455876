import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "currentColor",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M9.9995 18.9998C9.7735 18.9998 9.5465 18.9238 9.3595 18.7678C8.9355 18.4148 8.8785 17.7838 9.2315 17.3598L13.7075 11.9888L9.3925 6.62683C9.0465 6.19683 9.1145 5.56683 9.5445 5.22083C9.9755 4.87483 10.6045 4.94283 10.9515 5.37283L15.7795 11.3728C16.0775 11.7438 16.0735 12.2738 15.7685 12.6398L10.7685 18.6398C10.5705 18.8768 10.2865 18.9998 9.9995 18.9998Z"
    }, null, -1)
  ])))
}
export default { render: render }